@import '../../../../scss/_theme-bootstrap';

.content-block--button {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  b,
  strong {
    font-weight: bold;
  }
  .content-block__link {
    padding-bottom: 1em;
  }
}
